import React from 'react';
import SectionComponent from '../components/sections/sections';

const Blog: React.FC = (): JSX.Element => {
  return (
    <SectionComponent filter='Blog' />
  );
};

export default Blog;
